<template>
    <div class="newpost">
        <div class="submit-info">
            <input @blur="blur" type="text" class="input-title" v-model="title" placeholder="填写标题会有更多的赞哦~">
            <textarea  ref="contentArea"  @blur="blur" class="input-body" v-model="body" placeholder="请输入正文"></textarea>
            <div v-if="isemo" class="emo-block">
                <img v-for="_ in emo" :key="'emo'+_.id" :src="_.srcOut" @click="insertEmo(_)">
            </div>
            <div v-else>
            <div v-if="type==1" class="img-upload-bar">
                <div class="img-block" :class="{'img-loading':img==''}"  v-for="(img,imgI) in imgs" :key="'img-upload-'+imgI">
                    <span v-if="img" :style="{'background-image':'url('+img+')'}"></span>
                    <i v-if="img" @click="rm(imgI)"></i>
                </div>
                <div class="img-upload-btn img-block">
                    <input id="userAvatar" type="file" ref="uploadFile" @change="fileChange($event)" accept="image/*" multiple />
                </div>
            </div>
            <div v-if="type==2" class="img-upload-bar">
                <div class="img-block  video-upload-btn" style="width:100%" :class="{'img-loading':videoloading}" v-if="videosrc!=''||videoloading">
                    <i v-if="videosrc" @click="rmv()"></i>
                    <video  v-if="videosrc" :src="videosrc" controls></video>
                </div>
                <div class="img-upload-btn video-upload-btn img-block" v-if="!(videosrc!=''||videoloading)">
                    <input id="userAvatarV" type="file" ref="uploadFileV" @change="fileChangev($event)" accept="video/*"  />
                </div>
            </div>
            <div v-if="type==3" class="img-upload-bar">
                <div class="img-block  audio-upload-btn" style="width:100%" :class="{'img-loading':audioloading}"  v-if="audiosrc!=''||audioloading">
                    <i v-if="audiosrc" @click="rma()"></i>
                    <audio  v-if="audiosrc" :src="audiosrc" controls></audio>
                </div>
                <div class="img-upload-btn audio-upload-btn img-block" v-if="!(audiosrc!=''||audioloading)">
                    <input id="userAvatarA" type="file" ref="uploadFileA" @change="fileChangea($event)" accept="audio/*"  />
                </div>
            </div>
            <div v-if="type==5" class="img-upload-bar">
                <div class="img-block  audio-upload-btn" style="width:100%" v-if="recid>0">
                    <i @click="rmrec()"></i>
                    <audio  v-if="recsrc" :src="recsrc" controls></audio>
                </div>
                <div class="img-upload-btn rec-upload-btn img-block" 
                    :class="{recstop:isRecStart}"
                    v-if="recid==0"
                    @click="recBtn"
                    >
                    
                </div>
            </div>
            
            <div v-if="type==4" class="img-upload-bar">
                <input type="text" class="linkinput" v-model="postlink" placeholder="请输入链接，以http://或https://开头">
            </div>
            </div>
            <div class="send_type">
                <div class="send_fl"> 
                    <div @click="type=1;isemo=false">
                        <img src="~@/assets/img/send-icon1.png">
                        图文
                    </div>
                    <div @click="type=5;isemo=false">
                        <img src="~@/assets/img/send-icon2.png">
                        语音
                    </div>
                   <!-- <div>
                        <img src="~@/assets/img/send-icon3.png">
                        音乐
                    </div>-->
                    <div @click="type=2;isemo=false">
                        <img src="~@/assets/img/send-icon4.png">
                        视频
                    </div>
                    <div @click="type=4;isemo=false">
                        <img src="~@/assets/img/send-icon5.png">
                        链接
                    </div>
                    <div @click="type=3;isemo=false">
                        <img src="~@/assets/img/send-icon6.png">
                        音频
                    </div>
                </div>
                <div class="send_fr" @click="isemo=true">
                    <img src="~@/assets/img/send-icon7.png">
                    表情
                </div>
            </div>
            <div class="send_adr" @click="putaddr"> 
                <img src="~@/assets/img/send-adr.png">
                {{selectPoi==-1?'添加地点':pois[selectPoi].title}}
            </div>
        </div>
        <div class="topic">
            <div class="title">热门标签</div>
            <div class="topic-items">
                <div v-for="_ in topic" :key="'topic-k'+_.id" @click="topic_click(_.id)" :class="{fire:_.fire}">
                    #{{_.title}}#
                </div>
            </div>
        </div>
        <div class="submit-bar-wp">
            <div class="submit-bar-block"></div>
            <div class="submit-bar">
                <a href="javascript:;" @click="$router.back(-1)">取消</a>
                <a href="javascript:;" @click="submit" class="submit">发布</a>   
            </div>
        </div>
        <div class="poi-select" v-if="isShowPoi">
            <div class="title">请选择您的位置：</div>
            <div v-for="vv in pois" :key="'poi'+vv.id" @click="changePoi(vv.id)">
                {{vv.title}} ({{vv._distance}}米)
            </div>
            <div class="btn-wp"></div>
            <div class="btn" @click="isShowPoi=false">取消</div>
        </div>
  </div>
</template>

<script>

import Api from '@/api'
import Vue from 'vue'
export default {
  name: 'Home',
  data(){
    return {
      title:'',
      body:'',
      imgs:[],
      imgids:[],
      topic:[],
      audioid:0,
      videoid:0,

      postlink:'',
      type:1,
      videoloading:false,
      videosrc:'',
      audioloading:false,
      audiosrc:'',
      recsrc:'',
      recid:0,
      emo:[],
      isemo:false,
      pois:[],
      isShowPoi:false,
      selectPoi:-1,
      isRecStart:false,
    }
  },
  components: {

  },
  methods:{
      rmrec(){
          var that=this
        that.recsrc=''
        that.recid=0
      },
      recBtn(){
          var that=this
          console.log('isRecStart',this.isRecStart)
          if(this.isRecStart){
              this.isRecStart=false
              
              Api.wxgo(()=>{
                  
                  console.log('stopRecord')
                  wx.stopRecord({
                      fail:function(r){
                        console.log('stop record fail',r)  
                      },
                    success: function (res) {
                        var localId = res.localId;
                        
                  console.log('localId',localId)
                        wx.uploadVoice({
                        localId: localId, // 需要上传的音频的本地ID，由stopRecord接口获得
                        isShowProgressTips: 1, // 默认为1，显示进度提示
                        success: function (res) {
                            var serverId = res.serverId; // 返回音频的服务器端ID

                            Api.go('user_rec',{localId:localId,serverId:serverId},(res)=>{
                                that.recsrc=res.src
                                that.recid=res.id
                            })
                        }
                        });
                    }
                  });
              })
          }else{
              this.isRecStart=true
              Api.wxgo(()=>{
                  console.log('startRecord')
                  wx.startRecord({
                      
                      fail:function(r){
                        console.log('start record fail',r)  
                      },
                  });
              })
          }

      },
      changePoi(id){
          for(var i in this.pois){
              if(this.pois[i].id==id){
                  this.selectPoi=i;
                  this.isShowPoi=false;
                  break
              }
          }
      },
      openlatlng(latlng){
          var that=this
          console.log('openlatlng',latlng)
          Api.go('geocoderByLocation',{latlng:latlng},(d)=>{
              that.selectPoi=-1
              that.pois=d.result.pois
              console.log('geo',that.pois)
              that.isShowPoi=true
          })
      },
      putaddr(){

          var that=this
          Api.wxgo(()=>{
            
              wx.getLocation({
                type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: function (res) {
                    
                    var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                    var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                    that.openlatlng(latitude+','+longitude)
                    //var speed = res.speed; // 速度，以米/每秒计
                    //var accuracy = res.accuracy; // 位置精度
                    
                }
                });
          })
      },
      insertEmo(emo){
            const myField = this.$refs.contentArea;
            var value=emo.title
            if (myField.selectionStart || myField.selectionStart === 0) {
            let startPos = myField.selectionStart;
            let endPos = myField.selectionEnd;
            this.body = myField.value.substring(0, startPos) + value + myField.value.substring(endPos, myField.value.length);
            this.$nextTick(()=>{
                myField.focus();
                myField.setSelectionRange(endPos + value.length, endPos + value.length)
            })
            } else {
            this.body += value
            }
      },
      submit(){
        var topicids=[]
        var that=this
        for(var i in this.topic){
          if(this.topic[i].fire){
            topicids.push(this.topic[i].id)
          }
        }
        var data={
          title:this.title,
          body:this.body,
          imgids:this.imgids,
          topicids:topicids,
          audioid:this.audioid,
          videoid:this.videoid,
          postlink:this.postlink,
          posttype:this.type
        }
        if(this.type==5){
            data.type=3;
            data.audioid=this.srcid
        }
        if(this.selectPoi!=-1){
            data.lat=this.pois[this.selectPoi].location.lat
            data.lng=this.pois[this.selectPoi].location.lng
            data.locale_title=this.pois[this.selectPoi].title
        }
        
        Api.go('new_post',data,(res)=>{
          
          that.$router.replace('/post/'+res.id)
          
        })
      },
      blur(){
        window.scrollTo(0,0)
      },
      topic_click(id){
        for(var i in this.topic){
          if(this.topic[i].id==id){
            Vue.set(this.topic[i],'fire',!this.topic[i].fire)
          }
        }
      },
      rmv(){
          this.videoid=0;
          this.videoloading=false;
          this.videosrc='';
      },
      rma(){
          this.audioid=0;
        this.audiosrc='';
        this.audioloading=false;
      },
      rm(i){
          this.imgs.splice(i,1)
          this.imgids.splice(i,1)
      },
      fileChangev(event){
            var that = this
            var files=document.getElementById("userAvatarV").files;
            var file=files[0];
            that.videoloading=true
            var reader = new FileReader();
            reader.onloadend = function () {
                
                
                Api.go('upload_video',{video:reader.result},(res)=>{
                    if(that.videoloading){
                        that.videosrc=res.src
                        that.videoid=res.id
                        that.videoloading=false;
                    }
                
                })
            };
            reader.readAsDataURL(file);
      },
      fileChangea(event){
            var that = this
            var files=document.getElementById("userAvatarA").files;
            var file=files[0];
            that.audioloading=true
            var reader = new FileReader();
            reader.onloadend = function () {
                
                
                Api.go('upload_audio',{audio:reader.result},(res)=>{
                    if(that.audioloading){
                        that.audiosrc=res.src
                        that.audioid=res.id
                        that.audioloading=false;
                    }
                
                })
            };
            reader.readAsDataURL(file);
      },
       fileChange(event) {
            var that = this
            var files=document.getElementById("userAvatar").files
            
            for(var i=0;i<files.length;i++){
                var file=files[i]
                if(file){
                    (function(file){
                        
                    console.log('file abc',file)
                        var reader = new FileReader();
                        reader.onloadend = function () {
                            var ii=that.imgs.length
                            console.log('iiii',ii,that.imgs)
                            that.imgs.push('')
                            that.imgids.push(0)
                            Api.go('upload_img',{img:reader.result},(res)=>{
                                Vue.set(that.imgs,ii,res.src)
                                Vue.set(that.imgids,ii,res.id)
                            
                            })
                        };
                        reader.readAsDataURL(file);
                    })(file)
                }
            }
      },
  },
  mounted(){
    var that=this
    Api.go('ping',false,(res)=>{
    })
   // this.openlatlng('38.035802,114.506068')
    Api.go('topic_for_new',false,(res)=>{
      for(var i in res){
        if(res[i].id==that.$route.params.topic){
          res[i].fire=true
        }
      }
      that.topic=res
    }) 
    Api.go('post_emoticon',false,(res)=>{
        that.emo=res
    })
  }
}
</script>

<style lang="less" scoped>

.submit-info{
    padding:0 0.32rem .32rem;
    background: #fff;
    box-sizing: border-box;
    .input-title{
        border:0;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        line-height: 0.34rem;font-size: 0.24rem;
        display: block;
        width: 100%;
        padding:0.24rem 0;
        font-size: .24rem;
    }
    .input-body{
        border:0;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        line-height: 0.34rem;
        height:2rem;
        font-size: 0.24rem;
        display: block;
        width: 100%;
        padding:0.24rem 0;
        font-size: .24rem;
    }
}

.linkinput{font-size: 0.3rem;
    border: 1px solid #ccc;
    width: 100%;
    padding: 0.2rem;}

.img-upload-bar{flex-direction: row;padding:0.4rem 0;display: flex;flex-wrap: wrap;}
.img-upload-bar .img-block{position: relative;width: 2rem;height:2rem;border:1px solid #ccc;border-radius: 4px;margin-right: 0.15rem;margin-bottom: 0.15rem;}
.img-upload-bar .img-upload-btn{background:url(~@/assets/img/add-pic2.png) no-repeat center center/1.28rem 1.28rem;}
.img-upload-bar .img-upload-btn input{position: absolute;left: 0;top:0;width: 100%;height: 100%;opacity: 0;}
.img-upload-bar .audio-upload-btn{background:url(~@/assets/img/add-audio.png) no-repeat center center/1.28rem 1.28rem;}
.img-upload-bar .audio-upload-btn audio{width: 100%;height: 100%;background: #000000;display: block;}
.img-upload-bar .video-upload-btn{background:url(~@/assets/img/add-video.png) no-repeat center center/1.28rem 1.28rem;}
.img-upload-bar .video-upload-btn video{width: 100%;height: 100%;background: #000000;display: block;}
.img-upload-bar .img-block.img-loading{background:url(~@/assets/img/loading.png) no-repeat center center/1.28rem 1.28rem;}
.img-upload-bar .img-block span{position: absolute;width:100%;height: 100%;background-repeat: no-repeat;background-position: center center;background-size: contain;}
.img-upload-bar .img-block i{position: absolute;width: 0.4rem;height: 0.4rem;right:-0.2rem;top:-0.2rem;
background:url(~@/assets/img/close.png) #fff no-repeat center center/cover;border-radius: 0.4rem;}


.img-upload-bar .rec-upload-btn{background:url(~@/assets/img/rec-start.png) no-repeat center center/1.28rem 1.28rem;}
.img-upload-bar .rec-upload-btn:after{content: '开始录音';position: absolute;bottom: 0.02rem;font-size: 0.23rem;left: 0;width:100%;text-align: center;}

.img-upload-bar .rec-upload-btn.recstop{background-image:url(~@/assets/img/rec-stop.png)}
.img-upload-bar .rec-upload-btn.recstop:after{content: '停止录音';}

.send_type{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: .24rem 0;
    .send_fl{
        display: flex;
        align-items: center;
        div{
            font-size: .22rem;
            font-weight: 400;
            color: #999999;
            margin-right: .38rem;
            img{
                width: .4rem;
                height: .4rem;
                display: block;
            }
        }
    }
    .send_fr{
        font-size: .22rem;
        font-weight: 400;
        color: #999999;
        img{
            width: .4rem;
            height: .4rem;
            display: block;
        }
    }
}
.send_adr{
    min-width: 1.96rem;
    
    display: inline-block;
    padding: 0 0.3rem;
    width: auto;
    height: .64rem;
    line-height: .64rem;
    background: #F2F5F7;
    border-radius: .32rem;
    font-size: .24rem;
    color: #666;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: .26rem;
        height: .32rem;
        margin-right: .05rem;
    }
}


.topic{
    margin-top: .22rem;
    padding:0.32rem;
    background: #fff;
    .title{
        font-size: .28rem;
        font-weight: 400;
        color: #999999;
    }
    .topic-items{
        display: flex;
        flex-wrap: wrap;
        div{
            height: 0.4rem;
            line-height: 0.4rem;
            background: #EFEDED;
            padding:0 0.2rem;
            border-radius: 0.2rem;
            text-align: center;
            margin-top:0.2rem;
            margin-right: 0.24rem;
            white-space:nowrap;
            font-size: .28rem;
            color: #333;
            &.fire{
                background: #F65102;
                color:#fff;
            }
        }
    }
}

.submit-bar-block{
    height:1.1rem;
}
.submit-bar{
    height: 1.4rem;
    display: flex;background:#fff;
    position:fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    a{
        width: 1.72rem;
        height: 0.92rem;
        line-height: 0.92rem;
        background: #F2F5F7;
        border-radius: .46rem;
        text-align: center;
        font-size: .32rem;
        color: #333;
        &.submit{
            background: #F65102;
            color:#fff;
            width: 4.82rem;
        }
    }
}

.emo-block{    display: flex;
    flex-wrap: wrap;}
    .emo-block img{width: 0.8rem;height: 0.8rem;}
.poi-select{
    z-index: 999;
    position: fixed;overflow: auto;
    width: 100%;height: 100%;top:0;left: 0;
    background: #fff;
    font-size: 0.24rem;
}
.poi-select >div{padding: 0.14rem 1rem;line-height: 0.4rem;
border-bottom: 1px #ccc solid;}
.poi-select .btn{position: fixed;bottom: 0;left: 0;text-align: center;
width: 100%;padding: 0.14rem 0;}
.poi-select .btn-wp{border-bottom: 0;}
</style>

